import Dropzone from "dropzone";
import { Controller } from "stimulus";
import { DirectUpload } from "@rails/activestorage";
import {
  getMetaValue,
  findElement,
  removeElement,
  insertAfter,
  purgeFile
} from "helpers";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    let _controller = this;
    this.dropZone = createDropZone(_controller);

    if (_controller.mockFile) {
      let mockFile = _controller.mockFile;
      let callback = null;
      let crossOrigin = null;
      let resizeThumbnail = false;
      this.dropZone.displayExistingFile(mockFile, _controller.mockFileThumbUrl, callback, crossOrigin, resizeThumbnail);
    }
    this.hideFileInput();
    this.bindEvents();
    Dropzone.autoDiscover = false;
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  bindEvents() {
    this.dropZone.on("addedfile", file => {
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start();
      }, 500);
    });

    this.dropZone.on("removedfile", file => {
      if(file.controller){
        purgeFile(file.controller.hiddenInput.value);
        removeElement(file.controller.hiddenInput);
      } else{
        purgeFile(file.id);
      }
    });

    this.dropZone.on("canceled", file => {
      file.controller && file.controller.xhr.abort();
    });
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }

  get mockFile() {
    if(this.data.get("thumbnail")){
      let status = Dropzone.ADDED;
      let name = this.data.get("thumbnailName");
      let size = this.data.get("thumbnailSize");
      let id = this.data.get("fileId");
      let url = this.data.get("thumbnail");

      return { name: name, size: parseInt(size), status: status, url: url, id: id };
    }
    return null;
  }

  get mockFileUrl() {
    return this.data.get("thumbnail");
  }

  get mockFileThumbUrl() {
    return this.data.get("thumbnailThumb");
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 256;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    let submits = $(this.hiddenInput.form).closest('form').find(':submit');
    submits.prop('disabled', true);
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
      submits.prop('disabled', false);
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", event =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    dictFallbackMessage: "Tarayıcınız sürükle bırak dosya yüklemelerini desteklemiyor.",
    dictFallbackText: "Dosyalarınızı eski günlerdeki gibi yüklemek için lütfen aşağıdaki yedek formu kullanın.",
    dictFileTooBig: "Dosya çok büyük ({{filesize}}MiB). Maks. Dosya boyutu: {{maxFilesize}}MiB.",
    dictInvalidFileType: "Bu tür dosyaları yükleyemezsiniz.",
    dictResponseError: "Sunucu {{statusCode}} koduyla yanıt verdi.",
    dictCancelUpload: "Yüklemeyi iptal et",
    dictCancelUploadConfirmation: "Bu yüklemeyi iptal etmek istediğinizden emin misiniz?",
    dictRemoveFile: "Dosyayı kaldır",
    dictMaxFilesExceeded: "Başka dosya yükleyemezsiniz.",

    autoQueue: false
  });
}
